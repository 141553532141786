<template>
  <div id="app">
      <router-view />
  </div>
</template>

<script>
  export default {
    name: 'App',
    mounted () {
      window.scrollTo(0, 0)
    }
  }
</script>

<style >
 :root{
    --font: #fff;
    --font-light: #C6C6DE;
    --font-dark: #5B5B74;
    --font-lighter: #9E9EB2;
    --background-dark: #1D1D27;
    --background-dark-hover: #0F0F13;
    --background: #343444;
    --background-light: #404054;
    --background-sidemenu: #3E3E51;
    --background-accent: #4e4e66;
    --background-accent-hover: #8B8B9F;
    --background-landing: #262633;
    --grey: #2d2d40;
    --primary: #A1F8E6;
    --primary-dark: #63C6B1;
    --red: #E17079;
    --red-dark: #d1444f;
    --purple: #B1A1F8;
    --purple-dark: #8174b9;
    --orange: #F5C392;
    --orange-dark: #E8AF78;
    --blue: #7777E8;
    }

    html,
    body{
      margin: 0;
      font-family: Inter UI,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
      color: var(--font);
    }
  
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .primary-button {
        -moz-appearance: none;
        appearance: none;
        border: 0;
        outline: 0;
        padding: 8px 20px;
        font-weight: 700;
        border-radius: 5px;
        cursor: pointer;
    }

    .primary-button:focus {
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
        filter: brightness(105%);
    }

    .primary-button:active {
        transform: scale(0.95);
    }

  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.2s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }

  @keyframes fadein {
    from {
      opacity: 0.2;
    }

    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0.2;
    }

    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0.2;
    }

    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0.2;
    }

    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0.2;
    }

    to {
      opacity: 1;
    }
  }
</style>