import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './registerServiceWorker'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faCog, faSignOutAlt, faQuestion, faSearch, faCaretDown, faCaretUp, faBan, faArrowRight, faExpand, faTimes, faClock, faHome, faBars, faSortAlphaDown, faSortAlphaUp, faSortNumericDown, faSortNumericUp, faRandom, faShieldAlt, faSlidersH, faStopwatch, faCloud, faEllipsisH, faCode, faAt, faServer, faCreditCard, faUserAlt, faLifeRing, faWrench, faTrash, faExchangeAlt, faFilter } from '@fortawesome/free-solid-svg-icons'
import { faChrome, faFirefoxBrowser } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VTooltip from 'v-tooltip'

Vue.use(VTooltip)
 
library.add(faPlus, faCog, faSignOutAlt, faQuestion, faSearch, faCaretDown, faCaretUp, faBan, faArrowRight, faExpand, faTimes, faClock, faHome, faBars, faSortAlphaDown, faSortAlphaUp, faSortNumericDown, faSortNumericUp, faRandom, faShieldAlt, faSlidersH, faStopwatch, faCloud, faEllipsisH, faCode, faAt, faServer, faChrome, faFirefoxBrowser, faCreditCard, faUserAlt, faLifeRing, faWrench, faTrash, faExchangeAlt, faFilter)
 
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    capitalize(value){
      return value.charAt(0).toUpperCase() + value.slice(1); 
    }
  }
});

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
